import { convertPercentStringToDecimal } from "../../utils/convertPercentStringToDecimal";
import { formatDateFromInvoice } from "../../utils/formatDate";
import { utils, writeFile } from "xlsx";

export const allColumnsOrdered = [
	// columns that are related to both jib and rev
	// (some transformations are made in order to have shared columns)
	"Operator Name",
	"Owner Name",
	"Owner Number",
	"Check Number",
	"Check Date",
	"Check Amount",
	"cashDate",
	"note",
	"Operator CC",
	"Property Description",
	"Product Code Description",
	"Interest Type",
	"Owner Decimal",
	"Distribution Decimal",
	"Prod Date",
	// rev related columns
	"Price",
	"BTU Factor",
	"Gross Volume",
	"Gross Value",
	"Gross Taxes",
	"Gross Deducts",
	"Net Value",
	"Owner Volume",
	"Owner Value",
	"Owner Taxes",
	"Owner Deducts",
	"Owner Net Value",
	"Tax Type 1",
	"Gross Tax 1",
	"Net Tax 1",
	"Deduct Type 1",
	"Gross Deduct 1",
	"Net Deduct 1",
	// Jib related columns
	"OpCC",
	"AFE",
	"OpAFE",
	"Statement Amount",
	"Category",
	"Account",
	"Description",
	"Gross Amount",
	"Original",
	// account assignment columns below
	"Cash Call",
	"CLC",
	"CIC - DRILLING",
	"CIC",
	"TL & WE",
	"MISC REV",
	"LOE",
	"TAXES",
	// matching to ND data
	"Well Index Name",
	"Well File & Well Name",
];

// FORMATTER FUNCTIONS
export const mapOriginalInvoice = (original) => {
	return {
		"Operator Name": transformOperatorName(original.operator),
		"Owner Name": original.client.ELclientBaName,
		"Owner Number": original.opOwnerNum,
		"Check Number": original.docNum,
		"Check Date": original.receivedDate,
		"Check Amount": original.amtOriginal,
		cashDate: original.custom.cashDate,
		note: original.custom.note,
	};
};

// revenue formatters ------------------------------------------------
export const mapRevenueStatement = (statement) => {
	return {
		"Operator CC": statement.cc,
		"Property Description": statement.wellName,
	};
};

export const mapRevInterestToMetadata = (interest, key) => {
	return {
		"Product Code Description": key.toUpperCase(),
		"Interest Type": interest.type,
		"Owner Decimal": convertPercentStringToDecimal(
			interest.owner?.interestPercent
		),
		"Distribution Decimal": convertPercentStringToDecimal(
			interest.owner?.distributionPercent
		),
		"Prod Date": formatDateFromInvoice(interest.date),
	};
};

export const mapRevenueMineralInterest = (interest, key) => {
	return {
		Price: interest.property?.price,
		"BTU Factor": interest.btu,
		"Gross Volume": interest.property?.volume,
		"Gross Value": interest.property?.value,
		"Gross Taxes": interest.grossTaxes,
		"Gross Deducts": interest.grossDeducts,
		"Net Value": interest.netValue,
		"Owner Volume": interest.owner?.volume,
		"Owner Value": interest.owner?.value,
		"Owner Taxes": interest.ownerTaxes,
		"Owner Deducts": interest.ownerDeducts,
		"Owner Net Value": interest.ownerNetValue,
	};
};

export const mapTaxItem = (taxItem) => {
	return {
		"Tax Type 1": taxItem.type,
		"Gross Tax 1": taxItem.property?.value,
		"Net Tax 1": taxItem.owner?.value,
	};
};

export const mapDeductions = (deductItem) => {
	return {
		"Deduct Type 1": deductItem.type,
		"Gross Deduct 1": deductItem.property?.value,
		"Net Deduct 1": deductItem.owner?.value,
	};
};

// jib formatters ------------------------------------------------
// note column name mapping from Jibs
// "Cost Center": "Operator CC",
// 	"Well Name": "Property Description",
export const mapJibStatement = (statement) => {
	return {
		"Operator CC": statement.cc,
		"Property Description": statement.wellName,
		OpCC: statement.ccId,
		AFE: statement.afe || "",
		OpAFE: statement.afeOp || "",
		"Statement Amount": statement.amtOriginal,
	};
};

// note column name mapping from Jibs
// 	"Partner Decimal": "Distribution Decimal",
// 	"Activity Month": "Prod Date",
export const mapJibInterest = (interest, key) => {
	return {
		Category: key.toUpperCase(),
		Account: interest.account,
		Description: interest.desc,
		"Distribution Decimal": convertPercentStringToDecimal(
			interest.partnerPercent
		),
		"Prod Date": formatDateFromInvoice(interest.date),
		"Gross Amount": interest.amtGross,
		Original: interest.amtOriginal,
	};
};

// custom column formatters
export const mapAccountAssignment = (account, original) => {
	// 101.2 - original -> Cash Call
	// 301.1, 301.6 - original -> CLC

	if (typeof account !== "string") {
		account = account.toString();
	}
	switch (account) {
		case "101.2":
			return { "Cash Call": original };
		case "301.1":
		case "301.6":
			return { CLC: original };
		case "310.1":
			return { "CIC - DRILLING": original };
		case "310.2":
		case "310.3":
		case "310.6":
		case "310.7":
		case "310.4":
		case "310.5":
			return { CIC: original };
		case "312.1":
		case "312.4":
			return { "TL & WE": original };
		case "830.1":
			return { "MISC REV": original };
		case "920.1":
			return { TAXES: original };
		default:
			if (account.startsWith("900")) {
				return { LOE: original };
			}
	}
};

const transformOperatorName = (operatorName) => {
	if (operatorName.includes("SCANNED - ")) {
		operatorName = operatorName.replace("SCANNED - ", "");
	}
	const replacements = {
		"GRAYSON MILL ENERGY LLC": "GRAYSON MILL OPERATING LLC",
		"GRAYSON MILL ENERGY, LLC": "GRAYSON MILL OPERATING LLC",
		"GRAYSON MILL OPERATING, LLC": "GRAYSON MILL OPERATING LLC",
		"COP ON BEHALF-BURL. RES. O&G CO LP": "CONOCOPHILLIPS COMPANY",
		"HESS BAKKEN INVESTMENTSII": "HESS BAKKEN INVESTMENTS II LLC",
		"KODA RESOURCE OPERATING LLC": "KODA RESOURCES OPERATING LLC",
		"KODA RESOURCE OPERATING, LLC": "KODA RESOURCES OPERATING LLC",
		"MUREX PETROLEUM CORPORATION": "MUREX PETROLEUM CORP",
		"WHITING PETROLEUM CORPORATION": "WHITING OIL AND GAS CORPORATION",
		"FOUNDATION ENERGY MGMT LLC": "FOUNDATION ENERGY MANAGEMENT LLC",
		"MARATHON OIL COMPANY": "MARATHON OIL CO",
		"XTO ENERGY INC.": "XTO ENERGY INC",
		"FOUNDATION ENERGY MANAGEMENT, LLC": "FOUNDATION ENERGY MANAGEMENT LLC",
		"KRAKEN OPERATING, LLC": "KRAKEN OPERATING LLC",
		"PRIMA EXPLORATION, INC.": "PRIMA EXPLORATION INC.",
		"OVINTIV USA INC.": "OVINTIV USA INC",
		"XCL RESOURCES, LLC": "XCL RESOURCES LLC",
	};
	for (const [key, value] of Object.entries(replacements)) {
		if (operatorName.includes(key)) {
			operatorName = value;
			break;
		}
	}
	return operatorName;
};

// well index transformations ------------------------------------------------
export const mapWellIndexNameAndFile = (operatorCC, referenceData) => {
	// find opcc match in "FileNo" column of referenceData
	// get "CurrentWellName" from referenceData
	// if operator cc exists
	if (!operatorCC) {
		return {
			"Well Index Name": "",
			"Well File & Well Name": "",
		};
	}
	operatorCC = operatorCC.toString();
	const matchingReferenceEntry = referenceData.find(
		(entry) => entry["FileNo"].toString() === operatorCC
	);

	const returnDict = {
		"Well Index Name": matchingReferenceEntry ? matchingReferenceEntry["CurrentWellName"] : "",
		"Well File & Well Name": matchingReferenceEntry
			? `${matchingReferenceEntry["FileNo"]} - ${matchingReferenceEntry["CurrentWellName"]}`
			: "",
	};
	return returnDict;
};

// ###
// REFERENCE VALUES - not necessarily used for transformation
export const rawRevenueColumns = [
	"Operator Name",
	"Owner Name",
	"Owner Number",
	"Check Number",
	"Check Date",
	"Check Amount",
	"cashDate",
	"note",
	"Operator CC",
	"Property Description",
	"Product Code Description",
	"Interest Type",
	"Owner Decimal",
	"Distribution Decimal",
	"Prod Date",
	"Price",
	"BTU Factor",
	"Gross Volume",
	"Gross Value",
	"Gross Taxes",
	"Gross Deducts",
	"Net Value",
	"Owner Volume",
	"Owner Value",
	"Owner Taxes",
	"Owner Deducts",
	"Owner Net Value",
	"Tax Type 1",
	"Gross Tax 1",
	"Net Tax 1",
	"Tax Type 2",
	"Gross Tax 2",
	"Net Tax 2",
	"Tax Type 3",
	"Gross Tax 3",
	"Net Tax 3",
	"Tax Type 4",
	"Gross Tax 4",
	"Net Tax 4",
	"Tax Type 5",
	"Gross Tax 5",
	"Net Tax 5",
	"Tax Type 6",
	"Gross Tax 6",
	"Net Tax 6",
	"Tax Type 7",
	"Gross Tax 7",
	"Net Tax 7",
	"Tax Type 8",
	"Gross Tax 8",
	"Net Tax 8",
	"Tax Type 9",
	"Gross Tax 9",
	"Net Tax 9",
	"Tax Type 10",
	"Gross Tax 10",
	"Net Tax 10",
	"Deduct Type 1",
	"Gross Deduct 1",
	"Net Deduct 1",
	"Deduct Type 2",
	"Gross Deduct 2",
	"Net Deduct 2",
	"Deduct Type 3",
	"Gross Deduct 3",
	"Net Deduct 3",
	"Deduct Type 4",
	"Gross Deduct 4",
	"Net Deduct 4",
	"Deduct Type 5",
	"Gross Deduct 5",
	"Net Deduct 5",
	"Deduct Type 6",
	"Gross Deduct 6",
	"Net Deduct 6",
	"Deduct Type 7",
	"Gross Deduct 7",
	"Net Deduct 7",
	"Deduct Type 8",
	"Gross Deduct 8",
	"Net Deduct 8",
	"Deduct Type 9",
	"Gross Deduct 9",
	"Net Deduct 9",
	"Deduct Type 10",
	"Gross Deduct 10",
	"Net Deduct 10",
];

export const metadataColumnsForTypesTransform = [
	"Operator Name",
	"Owner Name",
	"Owner Number",
	"Check Number",
	"Check Date",
	"Check Amount",
	"cashDate",
	"note",
	"Operator CC",
	"Property Description",
	"Product Code Description",
	"Interest Type",
	"Owner Decimal",
	"Distribution Decimal",
	"Prod Date",
];

export const rawJibColummns = [
	"Operator Name",
	"Owner Name",
	"Owner Number",
	"Check Number",
	"Check Date",
	"Check Amount",
	"cashDate",
	"note",
	"Cost Center",
	"OpCC",
	"AFE",
	"OpAFE",
	"Well Name",
	"Statement Amount",
	"Category",
	"Account",
	"Description",
	"Partner Decimal",
	"Activity Month",
	"Gross Amount",
	"Original",
];

export const jibColumnMap = {
	"Cost Center": "Operator CC",
	"Well Name": "Property Description",
	"Partner Decimal": "Distribution Decimal",
	"Activity Month": "Prod Date",
};
